<template>
  <b-card
    v-if="userData"
  >
    <b-card-title>Mise à jour d'un utilisateur</b-card-title>
    <b-form
      @submit.prevent
      @submit="updateUser(userData)"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Email et pseudo"
            label-for="h-email"
            label-cols-md="4"
          >
            <b-form-input
              id="h-email"
              v-model="userData.email"
              type="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Prénom"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-first-name"
              v-model="userData.firstname"
              placeholder="Prénom"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Nom"
            label-for="h-last-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-last-name"
              v-model="userData.lastname"
              placeholder="Nom"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="téléphone"
            label-for="h-number"
            label-cols-md="4"
          >
            <b-form-input
              id="h-number"
              v-model="userData.phoneNumber"
              type="text"
              placeholder="Numéro"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Entreprise"
            label-for="h-enterprise"
            label-cols-md="4"
          >
            <v-select
              v-model="userData.enterprise"
              :options="enterpriseOptions.filter(onlyUnique).sort()"
              taggable
              push-tags
              placeholder="Entreprise"
              label="Entreprise"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Mot de passe"
            label-for="h-password"
            label-cols-md="4"
          >
            <b-form-input
              id="h-password"
              v-model="password"
              type="text"
              placeholder="Mot de passe"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="preferences.fullDataUploadRequired"
              checked="false"
              name="h-upload"
              switch
              inline
            >
              Force upload data
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group
            label="Channel"
            label-for="h-channel"
          >
            <b-form-checkbox
              v-model="preferences.channel"
              name="h-channel"
              value="prod"
            >
              Prod
            </b-form-checkbox>
            <b-form-checkbox
              v-model="preferences.channel"
              name="h-channel"
              value="beta"
            >
              Beta
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group
            label="Scopes"
            label-for="h-scopes"
          >
            <b-form-checkbox
              v-model="scopes"
              name="h-scopes"
              value="user"
            >
              user
            </b-form-checkbox>
            <b-form-checkbox
              v-model="scopes"
              name="h-scopes"
              value="admin"
            >
              admin
            </b-form-checkbox>
            <b-form-checkbox
              v-model="scopes"
              name="h-scopes"
              value="superadmin"
            >
              superadmin
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Sauvegarder
          </b-button>
          <b-link
            :to="{ name: 'users' }"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-primary"
            >
              Annuler
            </b-button>
          </b-link>
        </b-col>
      </b-row>

    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
  BCard, BCardTitle, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardTitle,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userId: null,
      userData: {
        email: null,
        firstname: null,
        lastname: null,
        phoneNumber: null,
        enterprise: null,
      },
      preferences: {
        channel: [],
        fullDataUploadRequired: false,
      },
      scopes: [],
      password: null,
      enterpriseOptions: [],
    }
  },
  mounted() {
    this.$store.dispatch('user/findUser', this.$router.currentRoute.params.id)
      .then(response => {
        this.userData.email = response.email
        this.userData.firstname = response.firstname
        this.userData.lastname = response.lastname
        this.userData.phoneNumber = response.phoneNumber
        this.userData.enterprise = response.enterprise
        this.preferences = JSON.parse(atob(response.preferences))
        this.$store.dispatch('user/findCredentials', this.$router.currentRoute.params.id)
          .then(cred => {
            this.scopes = cred.scopes
            this.password = cred.keySecret
          })
      })
    this.hydrateUserEnterprise()
  },
  methods: {
    async updateUser(userData) {
      const updateUser = userData
      updateUser.username = userData.email
      updateUser.preferences = btoa(JSON.stringify(this.preferences))
      const credentialData = {
        credential: {
          scopes: this.scopes,
          keyId: updateUser.username,
          keySecret: this.password,
        },
        consumerId: updateUser.username,
        type: 'key-auth',
      }
      await this.$store.dispatch('user/updateUser', { userId: this.$router.currentRoute.params.id, body: updateUser })
        .then(user => {
          this.$store.dispatch('user/updateCredentials', { body: credentialData })
            .then(() => this.$router.push({ name: 'users-view', params: { id: user.id } }))
        })
    },
    hydrateUserEnterprise() {
      this.$store.dispatch('user/findAllUsers')
        .then(response => {
          response.forEach(user => {
            this.enterpriseOptions.push(user.enterprise)
          })
        })
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
